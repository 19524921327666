import React, { useEffect, useState } from 'react';
import { EVENTS } from '../redux/constants';
import { Button } from 'reactstrap';
import "./relogin.css";

const ReLogin = () => {
    const [isRelogin, setIsRelogin] = useState(false);

    useEffect(() => {
      // Function to handle the custom event
      const handleLocalStorageChange = (event) => {
        console.log({event});
        console.log(event.detail,"DETAIL");
        if (event.detail?.isReLogin) {
            setIsRelogin(true)
        }
      };
      window.addEventListener(EVENTS.RE_LOGIN, handleLocalStorageChange);
      return () => {
        window.removeEventListener(EVENTS.RE_LOGIN, handleLocalStorageChange);
      };
    }, []);

    const handleClose = () =>{
      localStorage.removeItem("token_auth");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_data");
      window.location.href = "https://app.peasy.ai/login";
      setIsRelogin(false);
    }


    return (
        <>
            {
                isRelogin && <>
                    <div className='relogin_mask'></div>
                        <div className='relogin_popup'>
                            <p>Session expired. Please re-login.</p>
                            <button className='relogin_button' onClick={handleClose}>Re-Login</button>
                    </div>
                </>
            }
        </>
    );
};

export default ReLogin;
