export const GET_CAMPAIGN_DETAILS_CONSTANTS = {
  GET_CAMPAIGN_DETAILS: "GET_CAMPAIGN_DETAILS",
  GET_CAMPAIGN_DETAILS_STARTED: "GET_CAMPAIGN_DETAILS_STARTED",
  GET_CAMPAIGN_DETAILS_SUCCESS: "GET_CAMPAIGN_DETAILS_SUCCESS",
  GET_CAMPAIGN_DETAILS_NET_FAILED: "GET_CAMPAIGN_DETAILS_NET_FAILED",
  GET_CAMPAIGN_DETAILS_FAILED: "GET_CAMPAIGN_DETAILS_FAILED"
};

export const GET_CAMPAIGN_METRIC_CONSTANTS = {
  GET_CAMPAIGN_METRIC: "GET_CAMPAIGN_METRIC",
  GET_CAMPAIGN_METRIC_STARTED: "GET_CAMPAIGN_METRIC_STARTED",
  GET_CAMPAIGN_METRIC_SUCCESS: "GET_CAMPAIGN_METRIC_SUCCESS",
  GET_CAMPAIGN_METRIC_NET_FAILED: "GET_CAMPAIGN_METRIC_NET_FAILED",
  GET_CAMPAIGN_METRIC_FAILED: "GET_CAMPAIGN_METRIC_FAILED",
  FILTER_CAMPAIGN_METRIC_SUCCESS: "FILTER_CAMPAIGN_METRIC_SUCCESS"
};

// custom constants
// custom event listener events
export const EVENTS = {
  "RE_LOGIN":"re_login"
}
