import Axios from "axios";
import { BASE_URL } from "../StaticArray/StaticArray.jsx";
import { EVENTS } from "../redux/constants.js";

const axios = Axios.create({});

// declare a request interceptor
axios.interceptors.request.use(
  async config => {
    // perform a task before the request is sent
    // adding auth header
    if (!config.guest) {
      let token = localStorage.getItem("token_auth");
      if (!token) {
        window.location.href = `https://app.peasy.ai/login`;
      }
      config.headers["Authorization"] = "Bearer " + token;
    }

    return Promise.resolve(config);
  },
  error => {
    // handle the error
    return Promise.reject(error);
  }
);

// declare a response interceptor
const interceptor = axios.interceptors.response.use(
  async response => {
    return Promise.resolve(response);
  },
  async error => {
    console.log(error.response,"BASE ERR");
    if (error.response && error.response.status === 401) {
      axios.interceptors.response.eject(interceptor);
      // remove the expired token_auth first // .__sh__
      // need to work here
      await refreshToken();
      let token = localStorage.getItem("token_auth");
      error.response.config.headers["Authorization"] = "Bearer " + token;
      return axios(error.response.config);
    }
    // handle the response error
    return Promise.reject(error);
  }
);

const refreshInstance = Axios.create();

const refreshToken = () => {
  const refreshToken = localStorage.getItem("refresh_token");
  const refreshingCall = refreshInstance
    .get(`${BASE_URL}api/v1/refresh`, {
      headers: {
        Authorization: refreshToken
      }
    })
    .then(res => {
      localStorage.setItem("token_auth", res.data.access_token);
      localStorage.setItem("refresh_token", res.data.refresh_token);
      return res.data;
    })
    .catch(err => {
      console.log(err);
      // show a popup, keep value in local storage by triger an event
      // remove the user info, token and refresh token from the ReLogin.jsx component
      const event = new CustomEvent(EVENTS.RE_LOGIN, {
        detail: { isReLogin: true }
      });
      window.dispatchEvent(event);

      /*
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_data");
      window.location.href = "https://app.peasy.ai/login";
      */
    });
  //call logout function in catch case
  return refreshingCall;
};

export default axios;

