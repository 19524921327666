import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import "./css/app.css";
import "./css/bootstrap.css";
import "./css/app-raw.css";
import "./css/brand.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { toast, ToastContainer } from "react-toastify";
import { setCurrentUser } from "./../src/redux/actions/authActions";
import setAuthorizationToken from "./utils/setAuthorizationToken";

const store = configureStore();

if (localStorage.token_auth) {
  if (localStorage.user_data) {
    const user = JSON.parse(localStorage.user_data);
    store.dispatch(setCurrentUser(user));
  }
  setAuthorizationToken(localStorage.token_auth);
}

toast.configure({
  position: toast.POSITION.BOTTOM_LEFT,
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastContainer position="bottom-left" />
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

