import React, { useEffect } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import ProtectedRoute from "./utils/routes/ProtectedRoute";
import GuestRoute from "./utils/routes/GuestRoute";
import LoginPage from "./pages/LoginPage/LoginPage";
import Register from "./pages/Register/RegisterPage";
import BrandProfiles from "./pages/BrandProfilesPage/BrandProfiles";
import Clients from "./pages/ClientPages/Clients";
import Content from "./pages/Campaign/Content";
import BrandOverview from "./pages/BrandOverview/BrandOverview";
import Sidebar from "./pages/UserScreens/SideBar";
import PostBoost from "./pages/PostBoost/PostBoost";
import BrandCreate from "./pages/BrandCreate/BrandCreate";
import AdminSidebar from "./pages/AdminScreens/AdminSidebar";
import UserProfile from "./pages/UserScreens/Screens/UserProfile";
import Billing from "./pages/UserScreens/Screens/Billing";
import UserTeam from "./pages/UserScreens/Screens/UserTeam";
import Integration from "./pages/UserScreens/Screens/integration/Integration";
import ConnectionSetup from "./pages/UserScreens/Screens/connectionSetup/ConnectionSetup";
import APIKey from "./pages/UserScreens/Screens/APIKey";
import ChartReport from "./pages/CampaignReport/index";
import NotFound from "./pages/NotFoundPage/NotFound";
import initHelpHero from "helphero";
import CustomAudience from "./pages/CustomAudiencePage/CustomeAudience";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Dashboard from "./pages/BrandProfilesPage/Dashboard";
import RmfScreen from "./pages/BrandProfilesPage/rmf-screen/RmfScreen";
import { CANVA_REDIRECT_URL } from "./utils/canva";
import {
  PEASY_INTEGRATE_REDIRECT_URL,
  PS_CONNECT_REDIRECT_URL,
} from "./utils/ps-connect";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#c54fff",
      main: "#8d02fa",
      dark: "#5200c6",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff",
      main: "#fff",
      dark: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    // fontFamily: "Montserrat, Sans-Serif",
  },
});

var hlp = initHelpHero("lftPsKIU3FI");
hlp.anonymous();

const tagManagerArgs = {
  gtmId: "GTM-TX74NMK",
};

const App = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/">
          <Switch>
            <Route path="/" exact>
              <Redirect to="/login" />
            </Route>
            <Route
              path="/canva"
              render={(props) =>
                !localStorage.getItem("token_auth") ? (
                  <LoginPage {...props} />
                ) : (
                  <RedirectComponent
                    auth={localStorage.getItem("token_auth")}
                  />
                )
              }
            />
            <Route
              path="/ps-connect"
              render={(props) =>
                !localStorage.getItem("token_auth") ? (
                  <LoginPage {...props} />
                ) : (
                  <RedirectComponent
                    auth={localStorage.getItem("token_auth")}
                    psConnect={true}
                  />
                )
              }
            />
            <Route
              path="/peasy-integrate"
              render={(props) =>
                !localStorage.getItem("token_auth") ? (
                  <LoginPage {...props} />
                ) : (
                  <RedirectComponent
                    auth={localStorage.getItem("token_auth")}
                    psIntegrate={true}
                  />
                )
              }
            />
            <Route
              path="/external"
              render={(props) =>
                !localStorage.getItem("token_auth") ? (
                  <LoginPage externalUrl={true} isExternalRoute={true} />
                ) : (
                  <Redirect
                    {...props}
                    to={"/session/auth/billing" + props.location.search}
                  />
                )
              }
            />
            <GuestRoute exact path="/login/:grant?" component={LoginPage} />
            <GuestRoute exact path="/register" component={Register} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            {/* <ProtectedRoute
              exact
              path="/brand-profiles"
              component={BrandProfiles}
            /> */}
            <ProtectedRoute
              exact
              path="/brand/leads/:id?"
              component={Clients}
            />
            <ProtectedRoute exact path="/rmf/:brandId" component={RmfScreen} />
            <ProtectedRoute
              path="/brand/custom-audience/:brandId"
              component={CustomAudience}
            />

            <ProtectedRoute
              exact
              path="/campaign/create-new/:id"
              component={Content}
            />
            <ProtectedRoute
              exact
              path="/brand/overview/:id"
              component={BrandOverview}
            />
            <ProtectedRoute
              exact
              path="/campaign-report/:id"
              component={ChartReport}
            />
            <ProtectedRoute
              exact
              path="/campaign/edit/:id"
              component={PostBoost}
            />
            <ProtectedRoute exact path="/admin" component={AdminSidebar} />
            <ProtectedRoute
              exact
              path="/brand/create"
              component={BrandCreate}
            />
            <ProtectedRoute
              exact
              path="/brand/update/:id"
              component={BrandCreate}
            />

            <ProtectedRoute
              exact
              path="/session/auth/user-profile"
              component={UserProfile}
            />
            <ProtectedRoute
              exact
              path="/session/auth/billing"
              component={Billing}
            />
            <ProtectedRoute
              exact
              path="/session/auth/users-teams"
              component={UserTeam}
            />
            <ProtectedRoute
              exact
              path="/session/auth/integration"
              component={Integration}
            />
            <ProtectedRoute
              exact
              path="/session/auth/connection"
              component={ConnectionSetup}
            />
            <ProtectedRoute
              exact
              path="/session/auth/api-key"
              component={APIKey}
            />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  );
};

function RedirectComponent(params) {
  console.log("auth", params.auth);
  const { psConnect, psIntegrate } = params;
  let urlParams = new URLSearchParams(window.location.search);

  let userData = localStorage.getItem("user_data");
  userData = JSON.parse(userData);

  if (psIntegrate) {
    urlParams.append("pm_access_token", userData.pm_access_token);
    urlParams.append("fb_access_token", userData.fb_access_token);
  } else {
    urlParams.append("auth", params.auth);
    urlParams.append("userId", userData.id);
  }

  if (psConnect) {
    window.location = PS_CONNECT_REDIRECT_URL + "?" + urlParams;
  } else if (psIntegrate) {
    window.location = PEASY_INTEGRATE_REDIRECT_URL + "?" + urlParams;
  } else {
    window.location = CANVA_REDIRECT_URL + "?" + urlParams;
  }

  return null;
}

export default App;

