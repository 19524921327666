import axios from "axios";
import * as types from "./actionTypes";
import { BASE_URL } from "../../StaticArray/StaticArray";
import setAuthorizationToken from "../../utils/setAuthorizationToken";
import { toast } from "react-toastify";
import i18next from "i18next";
import { onUserLogin } from "../../utils/network/lib/auth";
import { onFbLogin } from "../../utils/network/lib/fb";
import { CANVA_REDIRECT_URL } from "../../utils/canva";
import { PEASY_INTEGRATE_REDIRECT_URL, PS_CONNECT_REDIRECT_URL } from "../../utils/ps-connect";

const loginSuccess = (payload) => {

  var qualification =
    payload.data.user_meta_data && payload.data.user_meta_data.qualification
      ? payload.data.user_meta_data.qualification
      : "";

  window.gist.identify(payload.data.id, {
    name: payload.data.name || "",
    phone_number: payload.data.phone,
    peasy_plan:
      (payload.data.active_package && payload.data.active_package.name) || "",
    qualification: qualification,
    account_monthly_balance: payload.data.balance || "",
  });
  try {
    if (payload.data?.role === "superadmin") {
      console.log("Superadmin :  Ignoring setUser");
      localStorage.setItem("prevent_set_user", "true");
    } else {
      // console.log("login event call...", payload.data.role);
      console.log("Setuser Called...");
      window.customSetUser();
      window.customSetUser();
    }
    // window.$peasy.reset();

    window.$peasy.triggerEvent({ event_name: "login" });
    console.log("login event called ");
  } catch (error) {
    console.log("trouble sending event", error);
  }
  window.gist.track("Login", { email: payload.data.email });
  console.log("loginSuccess path", payload.path);
  return {
    type: types.LOGIN_SUCCESS,
    payload,
  };
};

const loginError = (payload) => {
  return {
    type: types.LOGIN_ERROR,
    payload,
  };
};

const loginErrorMessage = (payload) => {
  return {
    type: types.LOGIN_ERROR_MESSAGE,
    payload,
  };
};

export function setCurrentUser(user) {
  return {
    type: types.SET_CURRENT_USER,
    payload: user,
  };
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem("token_auth");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_data");
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
  };
}

export const fbLogin = (fb_id, access_token) => {
  return (dispatch) => {
    let fbLoginPayload = {
      fb_id,
      access_token,
    };

    onFbLogin(fbLoginPayload)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("token_auth", res.data.data.access_token);
          localStorage.setItem("refresh_token", res.data.data.refresh_token);
          // res.data.data["subscription_status"] = "inactive";
          localStorage.setItem("user_data", JSON.stringify(res.data.data));
          localStorage.setItem("fbConnectFlag", true);
          return dispatch(loginSuccess({ data: res.data.data }));
        } else {
          return dispatch(loginError({ data: res.data.data }));
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          const object = {};
          object.emailError = err.response.data.errors.email[0];
          if (err.response.data.errors.password) {
            object.passwordErr = err.response.data.errors.password[0];
          }
          return dispatch(loginErrorMessage(object));
        } else {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }

        return dispatch(loginError(err.response.data));
      });
  };
};

export const loginUser = (email, password, path) => {
  return (dispatch) => {
    // let urlParams = new URLSearchParams(window.location.search);
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const {ps_access_token,ps_account_id} = params

    console.log('login urlParams',ps_access_token,ps_account_id)

    let loginReqPayload = {
      password,
      email,
      ps_access_token,
      ps_account_id
    };
    onUserLogin(loginReqPayload)
      .then((res) => {
        console.log("login res", res);
        if (res.status === 200) {
          localStorage.setItem("token_auth", res.data.data.access_token);
          localStorage.setItem("refresh_token", res.data.data.refresh_token);
          // res.data.data["subscription_status"] = "inactive";
          localStorage.setItem("user_data", JSON.stringify(res.data.data));
          localStorage.setItem("fbConnectFlag", true);
          i18next.changeLanguage(res.data.data.language || "en");

          let urlParams = new URLSearchParams(window.location.search);

          let userData = localStorage.getItem("user_data");
          userData = JSON.parse(userData);
          console.log(res.data.data," intitial login res data storage DB");

          // // lock the screen to account setup screen _sh_
          // if (userData.upgraded_prepaid?.toString() !== '1') {
          //   console.log(userData.upgraded_prepaid,"check upgraded_prepaid==1");
          //   window.location = "/session/auth/connection";
          // }


          if (path.pathname === "/peasy-integrate") {

            urlParams.append("pm_access_token", userData.pm_access_token);
            urlParams.append("fb_access_token", userData.fb_access_token);

            window.location = PEASY_INTEGRATE_REDIRECT_URL + "?" + urlParams;

          } else  {
            urlParams.append("auth", userData.access_token);
            urlParams.append("userId", userData.id);
          }

          if (path.pathname === "/canva") {
            window.location = CANVA_REDIRECT_URL + "?" + urlParams;
          }
          if (path.pathname === "/ps-connect") {
            window.location = PS_CONNECT_REDIRECT_URL + "?" + urlParams;
          }

          return dispatch(loginSuccess({ data: res.data.data, path: path }));
        } else {
          return dispatch(loginError({ data: res.data.data }));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 422) {
          const object = {};
          if (err.response.data.status) {
            object.status = err.response.data.status;
            object.user_id = err.response.data.user_id;
          }
          if (err.response.data.errors) {
            if (err.response.data.errors.email) {
              object.emailError = err.response.data.errors.email[0];
            }
            if (err.response.data.errors.password) {
              object.passwordErr = err.response.data.errors.password[0];
            }
            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          return dispatch(loginErrorMessage(object));
        } else if (err.response.status === 404) {
          toast.error("Requested source not found", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }

        return dispatch(loginError(err.response.data));
      });
  };
};

